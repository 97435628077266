import request from '@/utils/bbsrequest.js'
// 精华贴子接口
export function getFidEssenceT(params) {
  return request({
    url: '/thread/thread/getFidEssenceT',
    method: 'get',
    params
  })
}
// WabFind页面
export function findDate(params) {
  return request({
    url: '/group/group/getGroupListByCategoryID',
    methods: 'get',
    params
  })
}
// WabFind页面分类
export function categorywDate(params) {
  return request({
    url: '/group/classify/getClassifyForWap',
    methods: 'get',
    params
  })
}

