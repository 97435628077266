<template>
  <div class="cnt">
    <div v-if="setpNumber==0" class="prop_box">
      <div class="prop_box_top">
        <van-sticky>
          <h1>选择兴趣的专业</h1>
        </van-sticky>
      </div>
      <div v-for="(v,i) in nav_list" :key="i" class="prop_box_bottom">
        <h2>{{ v.nickname }}</h2>
        <ul>
          <li v-for="(item,index) in v.child" :key="index" class="itemProfress" @click="none(item)">{{ item.nickname }}</li>
        </ul>
      </div>
    </div>
    <div v-else>
      <div v-if="fromChange">
        <heads :more="showMore" :msg="title"></heads>
      </div>
      <div v-else class="header">
        <span class="skip" @click="skip">跳过</span>
      </div>
      <div class="midBox">
        <!-- step1 -->
        <div v-if="setpNumber === 1" class="step1">
          <p v-if="fromChange" style="margin-top:20px;"></p>
          <p v-else class="welcome">欢迎来到{{ agency_name }}</p>
          <van-uploader :after-read="afterReadImage" :multiple="false" accept="image/*" :preview-image="false">
            <div class="avatarbg">
              <img v-if="avatarUrl" class="avatar" :src="avatarUrl" alt="">
              <img v-else class="avatar" src="@/static/images/my_index/ava_icon.png" alt="">
              <span v-if="avatarUrl" class="edit">编辑</span>
            </div>
          </van-uploader>
          <p class="info">上传头像可获得<span style="color:#ee2e2e;">{{ avatarGet }}{{ scoreName }}</span></p>
          <p class="info info1">{{ avatarGet }}{{ scoreName }}可兑换海量<span style="color:#ee2e2e;">京东好货</span></p>
          <div v-show="!fromChange" class="sex">
            <div v-if="gender === 0" class="btn2" @click="selectSex(1)"><img src="@/static/images/my_index/boy_white.png" alt=""><span>男生</span></div>
            <div v-else class="btn1" @click="selectSex(0)"><img src="@/static/images/my_index/boy_gray.png" alt=""><span>男生</span></div>
            <div v-if="gender === 1" class="btn2" @click="selectSex(2)"><img src="@/static/images/my_index/girl_white.png" alt=""><span>女生</span></div>
            <div v-else class="btn1" @click="selectSex(1)"><img src="@/static/images/my_index/girl_gray.png" alt=""><span>女生</span></div>
          </div>
        </div>
        <!-- step2 -->
        <div v-else-if="setpNumber === 2" class="step2">
          <div v-if="!fromSet" :class="fromChange ? 'topInfo topInfoTop' : 'topInfo'">
            <p class="info1">填写资料，让我更懂您</p>
            <p class="info2">您填写的信息将被严格保密，仅用于为您匹配更优质的内容及{{ agency_name }}好友！</p>
            <p class="info3">完善可获得<span style="color:#ee2e2e;">{{ infoGet }}{{ scoreName }}</span>，兑海量<span style="color:#ee2e2e;">京东好货</span></p>
          </div>
          <div class="table">
            <van-form label-width="70px" @submit="onSubmitDatas">
              <div v-for="(item,index) in pageListData" :key="index" class="div_item">
                <!--通用文本-->
                <div v-if="item.type==1||item.type==9">
                  <van-field v-if="item.fields_remark.includes('标签')" v-model="tagValue" :disabled="item.type==1&&item.disabled==1" :label="item.fields_remark" :placeholder="'多个'+item.fields_remark+'用英文逗号分割'" @input="inputValue" />
                  <van-field v-else-if="item.fields_remark.includes('证书')" v-model="certValue" :disabled="item.type==1&&item.disabled==1" :label="item.fields_remark" :placeholder="'多个'+item.fields_remark+'用英文逗号分割'" @input="inputValue" />
                  <van-field v-else v-model="item.value" :disabled="item.type==1&&item.disabled==1" :label="item.fields_remark" :placeholder="'请输入'+item.fields_remark" @input="inputValue" />
                  <span></span>
                </div>
                <!--性别-->
                <div v-if="item.type==2" class="inputAndIcon">
                  <van-field v-model="item.value==0 ?'男':'女'" readonly clickable :label="item.fields_remark" :placeholder="'请选择'+item.fields_remark" @click="showSexPicker = true" @input="inputValue" />
                  <van-popup v-model="showSexPicker" position="bottom">
                    <van-picker show-toolbar :columns="sexList" :default-index="sexIndex" value-key="label" @confirm="(val,index)=>onConfirmSex(val, index,item)" @cancel="showSexPicker = false" />
                  </van-popup>
                  <img class="rightArrow" src="@/static/images/my_index/reg_right_arrow.png" alt="">
                </div>
                <!--生日-->
                <div v-if="item.type==3" class="inputAndIcon">
                  <van-field v-model="item.value" readonly clickable :label="item.fields_remark" :placeholder="'请选择'+item.fields_remark" @click="showBirthdayCalendar = true" @input="inputValue" />
                  <van-popup v-model="showBirthdayCalendar" position="bottom">
                    <van-datetime-picker
                      v-model="currentDate"
                      type="date"
                      title="请选择年月日"
                      :min-date="minDate"
                      :max-date="maxDate"
                      :formatter="formatter"
                      @confirm="(date)=>onConfirmBirthday(date,item)"
                      @cancel="showBirthdayCalendar = false"
                    />
                  </van-popup>
                  <img class="rightArrow" src="@/static/images/my_index/reg_right_arrow.png" alt="">
                </div>
                <!--兴趣点|专业-->
                <div v-if="item.type==10" class="inputAndIcon">
                  <van-field readonly clickable :value="item.value1.specialty" :label="item.fields_remark" :placeholder="'请选择'+item.fields_remark" @click="showMajorPicker = true" />
                  <van-popup v-model="showMajorPicker" position="bottom">
                    <van-picker show-toolbar :columns="majorList" :default-index="normalSpecialIndex" value-key="category_name" @confirm="(val,index)=>onConfirmMajor(val, index,item)" @cancel="showMajorPicker = false" />
                  </van-popup>
                  <img class="rightArrow" src="@/static/images/my_index/reg_right_arrow.png" alt="">
                </div>
                <!--地区-->
                <div v-if="item.type==6" class="inputAndIcon">
                  <van-field
                    readonly
                    clickable
                    :value="provinceAndCityName"
                    :label="item.fields_remark"
                    :placeholder="'请选择'+item.fields_remark"
                    @click="clickForAddress(item)"
                  />
                  <img class="rightArrow" src="@/static/images/my_index/reg_right_arrow.png" alt="">
                </div>
                <!--学历-->
                <div v-if="item.type==11" class="inputAndIcon">
                  <van-field readonly clickable :value="eduName" :label="item.fields_remark" :placeholder="'请选择'+item.fields_remark" @click="showEduPicker = true" />
                  <van-popup v-model="showEduPicker" position="bottom">
                    <van-picker show-toolbar :columns="options" :default-index="normalEduIndex" value-key="label" @confirm="(val,index)=>onConfirmEdu(val, index,item)" @cancel="showEduPicker = false" />
                  </van-popup>
                  <img class="rightArrow" src="@/static/images/my_index/reg_right_arrow.png" alt="">
                </div>
                <!--工作年限-->
                <div v-if="item.type==13" class="inputAndIcon">
                  <van-field readonly clickable :value="workYearNum" :label="item.fields_remark" :placeholder="'请选择'+item.fields_remark" @click="showWorkPicker=true" />
                  <van-popup v-model="showWorkPicker" position="bottom">
                    <van-picker
                      ref="addRefWork"
                      show-toolbar
                      :columns="workYear"
                      :default-index="normalWorkIndex"
                      value-key="label"
                      @confirm="(val,index)=>onConfirmWork(val, index,item)"
                      @cancel="showWorkPicker = false"
                    />
                  </van-popup>
                  <img class="rightArrow" src="@/static/images/my_index/reg_right_arrow.png" alt="">
                </div>
                <!--毕业时间-->
                <div v-if="item.type==12" class="inputAndIcon">
                  <van-field readonly clickable :value="item.value" :label="item.fields_remark" :placeholder="'请选择'+item.fields_remark" @click="showDatePicker = true, handleItem = item" />
                  <img class="rightArrow" src="@/static/images/my_index/reg_right_arrow.png" alt="">
                </div>
                <!--证书|标签-->
                <div v-if="item.type==4" class="inputAndIcon">
                  <!-- 资格证书 -->
                  <van-field v-model="item.value" :label="item.fields_remark" :placeholder="item.fields_remark.includes('标签') ?'多个标签用英文逗号隔开':'多个证书用英文逗号隔开'" @input="inputValue" />
                  <span></span>
                </div>
              </div>
            </van-form>
          </div>
          <van-popup v-model="showAddressPicker" position="bottom">
            <van-picker
              ref="addRef"
              show-toolbar
              :default-index="normalProvinceIndex"
              :columns="addressList"
              value-key="name"
              @confirm="onConfirmAddress"
              @cancel="showAddressPicker = false"
            />
          </van-popup>
          <van-popup v-model="showMajorPicker" position="bottom">
            <van-picker show-toolbar :columns="majorList" :default-index="normalSpecialIndex" value-key="category_name" @confirm="(val,index)=>onConfirmMajor(val, index,item)" @cancel="showMajorPicker = false" />
          </van-popup>
          <van-popup v-model="showDatePicker" position="bottom">
            <van-picker :default-index="normalTimeIndex" show-toolbar :columns="datesArray" @confirm="onConfirmDate" @cancel="showDatePicker = false" />
          </van-popup>
        </div>
        <!-- step3 -->
        <div v-else-if="setpNumber === 3" class="step3">
          <div :class="fromChange ? 'topInfo topInfoTop' : 'topInfo'">
            <p class="info1">你可能认识</p>
            <p class="info2">
              关注<span style="color:#ee2e2e;">{{ attentionNum }}</span>个可能认识的人，更好的相互交流吧！<br>同时获得<span style="color:#ee2e2e;">{{ attentionGet }}{{ scoreName }}</span>，可兑换海量<span style="color:#ee2e2e;">京东好货</span>！
            </p>
          </div>
          <div class="listBox">
            <div v-for="item in members" class="list">
              <div class="left">
                <img onerror="javascript:this.src='https://newoss.zhulong.com/tfs/noavatar_big.gif'" :src="item.avatar" alt="">
              </div>
              <div class="midInfo">
                <p class="uname">{{ item.username }}</p>
                <div class="infos">
                  <span class="fans">粉丝：{{ item.fans_num }}</span>
                  <span class="from">{{ item.from }}</span>
                </div>
              </div>
              <div class="right">
                <span v-if="item.is_follow === 0" class="guanzhu" @click="addGuanzhu(item)">+关注</span>
                <span v-else class="isguanzhu">已关注</span>
              </div>
            </div>
          </div>
        </div>
        <div v-else></div>
      </div>
      <div v-if="setpNumber === 3" class="botDone">
        <span v-if="selCount >= needCount && selCount > 0" class="doneBtn bg1" @click="doneSelectFans">我已选好{{ selCount }}/{{ needCount }}</span>
        <span v-else class="doneBtn bg">我已选好{{ selCount }}/{{ needCount }}</span>
      </div>
      <div v-else class="bot">
        <div v-if="fromChange">
          <span v-if="canNext" v-show="pageListData && pageListData.length>0" class="nextBtn bg1" @click="saveInfos">确定</span>
          <span v-else v-show="pageListData && pageListData.length>0" class="nextBtn bg" @click="checkStep2CanClick(true)">确定</span>
        </div>
        <div v-else>
          <span v-if="canNext" class="nextBtn bg1" @click="clickNextStep">下一步</span>
          <span v-else class="nextBtn bg" @click="checkStep2CanClick(true)">下一步</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Cookie from 'js-cookie'
import axios from 'axios'
import { setCategory, getNewRegisInfo, newRegisInfo, getRegisInfo, followGetScore, newTaskIndex, getMajorList, addFollow, followList, getDistrict, updateAvatar, updateRegistInfo, getEduConfig, wxchectMessage } from '@/api/regInfo.js'
import { categorywDate } from '@/api/infohome.js'
import heads from '@/compontens/heads.vue'
import WechatState from '@/utils/isWechatState.js'

export default {
  components: {
    heads
  },
  data() {
    return {
      normalWorkIndex: 0,
      normalEduIndex: 0,
      normalSexIndex: 0,
      normalTimeIndex: 0,
      gender: '',
      tagValue: '',
      certValue: '',
      eduName: '',
      workYearNum: '',
      showWorkPicker: false,
      showEduPicker: false,
      options: [],
      handleItem: '',
      provinceAndCityName: '',
      showSexPicker: false,
      showBirthdayCalendar: false,
      currentDate: new Date(1900, 0, 1), // 默认选中当天日期
      minDate: new Date(1900, 0, 1),
      maxDate: new Date(),
      sexList: [{
        value: '0',
        label: '男'
      }, {
        value: '1',
        label: '女'
      }],
      workYear: [
        { value: '0', label: '一年或一年以内' },
        { value: '1', label: '二年' },
        { value: '2', label: '四年' },
        { value: '3', label: '三年' },
        { value: '4', label: '五年' }
      ],
      pageListData: [],
      // 旧数据分割
      showMore: false,
      title: '个人信息',
      redirect: this.$route.query.redirect ? this.$route.query.redirect : window.location.origin,
      canNext: false,
      // 从设置过来
      fromSet: this.$route.query.fromSet == 'true' || false,
      // true 是从修改信息过来的
      fromChange: false,
      setpNumber: 1,
      // 第一步
      avatarUrl: '',
      avatarGet: 0,
      infoGet: 0,
      // 第二步骤
      datesArray: [...Array(201)].map((k, i) => i + 1 + 1899 + '年'),
      showAddressPicker: false,
      addressList: [],
      showDatePicker: false,
      showCertifyPicker: false,
      certifyLists: [],
      showMajorPicker: false,
      majorList: [],

      normalSpecialIndex: 0,
      sexIndex: 0,

      normalProvinceIndex: 0,
      normalCityIndex: 0,
      normalCountyIndex: 0,
      isSelectedAddress: false,
      infoDatas: {
        // ---- 这几个是暂时置为空的
        username: '',
        intro: '',
        birthday: '',
        degree: '',
        // ----
        // 专业
        specialty: '',
        specialty_id: 0,
        // 姓名
        realname: '',
        // 性别
        gender: -1,
        // 毕业学校
        school_name: '',
        // 地址
        address: '',
        // 省
        province_id: '',
        // 市
        city_id: '',
        // 县区
        county_id: '',
        // 所学专业
        major: '',
        // 毕业时间
        graduation_time: '',
        // 公司名称
        company: '',
        // 资格证书
        certificate: ''
      },
      // 第三步
      members: [],
      needCount: 0,
      selCount: 0,
      attentionNum: 0,
      attentionGet: 0,
      // 积分名字
      scoreName: '',
      // 机构简称
      agency_name: '',
      nav_list: [],
      // 老用户名
      old_username: ''
    }
  },
  created() {
    // 获取学历信息
    getEduConfig().then((res) => {
      const result = res.result
      for (let i = 0; i < result.length; i++) {
        var obj = {
          value: '',
          label: ''
        }
        obj.value = result[i].id
        obj.label = result[i].education
        this.options.push(obj)
      }
    })
    if (!Cookie.get('uid')) {
      this.$router.push({
        path: '/login',
        query: {
          redirect: window.location.href
        }
      })
    }
    const queryStep = this.$route.query.step
    this.setpNumber = Number(queryStep)
    if (queryStep != '0') {
      this.fromChange = true
    } else {
      this.categorywDate()
    }
    console.log(this.fromChange)
    this.getRegisInfo()
    this.getConfig()
    this.getAddressLists()
    this.getFollowList()
    this.getMajorLists()
  },
  methods: {
    none(item) {
      const info = {
        category_id: item.fid
      }
      setCategory(info).then(res => {
        if (res.errNo == 0) {
          this.infoDatas.specialty = item.nickname
          this.infoDatas.specialty_id = Number(item.fid)
          this.setpNumber = 1
          Cookie.set('fid', item.fid)
          Cookie.set('Fname', item.nickname)
        } else {
          this.$toast.fail(res.result.msg)
        }
      })
    },
    // 获取版块分类
    categorywDate() {
      const info = {
        type: 2
      }
      categorywDate(info).then(res => {
        console.log(res, 'res')
        if (res.errNo == 0) {
          this.nav_list = res.result ? res.result : []
          console.log(this.nav_list)
        }
      })
    },
    // 返回
    goBack() {
      this.$router.go(-1)
    },
    // 获取注册信息
    getRegisInfo() {
      getNewRegisInfo().then(res => {
        console.log(res)
        const errNo = res.errNo
        if (errNo === 0) {
          const result = res.result
          this.pageListData = res.result
          this.pageListData.forEach(item => {
            if (item.type == 1) {
              this.old_username = item.value
            }
            if (item.type == 6) {
              if (!item.value1) {
                item.value1 = {}
              } else {
                this.provinceAndCityName = item.value1.province + ' ' + item.value1.city + ' ' + item.value1.county
              }
            }
            if (item.type == 1 && (item.fields_remark.includes('标签') || item.fields_remark.includes('证书'))) {
              if (item.fields_remark.includes('标签')) {
                this.tagValue = item.value2.join(',')
              } else if (item.fields_remark.includes('证书')) {
                this.certValue = item.value2.join(',')
              }
            }
            if (item.type == 11) {
              this.options.forEach(item1 => {
                console.log(item1.value + '---->' + item.value)
                if (item1.value == item.value) {
                  this.eduName = item1.label
                  console.log(JSON.stringify(item1) + '---->' + item.value)
                }
              })
            }
            if (item.type == 13) {
              this.workYear.forEach(item1 => {
                console.log(item1.value + '---->' + item.value)
                if (item1.value == item.value) {
                  this.workYearNum = item1.label
                  console.log(JSON.stringify(item1) + '---->' + item.value)
                }
              })
            }
            // 兴趣点
            if (item.type == 10) {
              // 专业index
              if (!item.value1) {
                item.value1 = {}
              }
              this.getSpecialtyIndex(item.value1.specialty_id)
            }
            // 生日
            if (item.type == 3 && item.value) {
              // 专业index
              const split = item.value.split('-')
              this.currentDate = new Date(split[0], split[1], split[2])
            }
            // 地址
            if (item.type == 6) {
              // 地址 index
              if (!item.value1) {
                item.value1 = {}
              }
              this.getAddressIndex(item.value1)
            }
            // 兴趣点
            if (item.type == 13 || item.type == 11 || item.type == 12 || item.type == 2) {
              // 地址 index
              this.setChecked(item)
            }
          })
          this.checkStep2CanClick()
        }
      })
    },
    setChecked(item) {
      if (item.type == 2) {
        this.sexList.forEach((itemS, index) => {
          console.log(itemS.value, item.value)
          if (itemS.value == item.value) {
            this.sexIndex = index
            console.log(this.sexIndex, '--------')
          }
        })
      } else if (item.type == 13) {
        this.workYear.forEach((itemS, index) => {
          if (itemS.value == item.value) {
            this.normalWorkIndex = index
            console.log(this.normalWorkIndex, '--------')
          }
        })
      } else if (item.type == 11) {
        this.options.forEach((itemS, index) => {
          if (itemS.value == item.value) {
            this.normalEduIndex = index
            console.log(this.normalWorkIndex, '--------')
          }
        })
      } else if (item.type == 12) {
        this.datesArray.forEach((itemS, index) => {
          if (itemS == item.value) {
            this.normalTimeIndex = index
            console.log(this.normalTimeIndex, '-------this.normalTimeIndex')
          }
        })
      }
    },
    // 配置信息
    getConfig() {
      newTaskIndex().then(res => {
        console.log(res)
        const result = res.result
        this.scoreName = result.scoreName
        this.agency_name = result.name
        const tasks = result.tasklist
        this.avatarGet = tasks[0].score
        this.infoGet = tasks[1].score
        this.attentionGet = tasks[2].score
        this.attentionNum = tasks[2].need_count
      })
    },
    // 获取专业
    getMajorLists() {
      const p = {
        is_old: '1'
      }
      getMajorList(p).then(res => {
        console.log(res)
        const errNo = res.errNo
        if (errNo === 0) {
          const result = res.result ? res.result : {}
          const list = result.list ? result.list : []
          this.majorList = []
          list.forEach(e => {
            const children = e.children ? e.children : []
            this.majorList = [...this.majorList, ...children]
          })
          this.getSpecialtyIndex()
        }
      })
    },
    // 专业index
    getSpecialtyIndex(specialty_id) {
      if (specialty_id && this.majorList.length > 0) {
        this.majorList.forEach((e, idx) => {
          if (e.id == specialty_id) {
            this.normalSpecialIndex = idx
            return false
          }
        })
      } else {
        this.normalSpecialIndex = 0
      }
    },
    // 获取关注列表
    getFollowList() {
      const params = {
        page: 1,
        limit: 100
      }
      followList(params).then(res => {
        const errNo = res.errNo
        if (errNo === 0) {
          const result = res.result ? res.result : {}
          this.members = result.list ? result.list : []
          const follow_num = result.follow_num
          this.selCount = follow_num
          // var add_num = 0
          // this.members.forEach(e => {
          //   if (e.is_follow === 1) {
          //     add_num++
          //   }
          // })
          // this.selCount = add_num
          this.needCount = result.need_num
        }
      })
    },
    // 获取地址信息
    getAddressLists() {
      const p = {
        app: 1
      }
      getDistrict(p).then(res => {
        console.log(res)
        const errNo = res.errNo
        if (errNo === 0) {
          const result = res.result
          this.addressList = result
          // 地址 index
          this.getAddressIndex()
        }
      })
    },
    // 地址 index
    getAddressIndex(item) {
      if (item && item.province_id && item.county_id && this.addressList.length > 0) {
        const p_id = parseInt(item.province_id)
        const city = parseInt(item.city_id)
        const county = parseInt(item.county_id)
        this.addressList.forEach((e1, idx1) => {
          if (e1.id === p_id) {
            this.normalProvinceIndex = parseInt(idx1)
            e1.children.forEach((e2, idx2) => {
              if (e2.id === city) {
                this.normalCityIndex = parseInt(idx2)
                e2.children.forEach((e3, idx3) => {
                  if (e3.id === county) {
                    this.normalCountyIndex = parseInt(idx3)
                    return
                  }
                })
                return
              }
            })
            return
          }
        })
      }
    },
    // 选性别
    selectSex(val) {
      this.gender = val
      // 检测是否可下一步
      this.checkStep1CanClick()
    },
    // 选头像
    afterReadImage(file) {
      var fd = new FormData()
      fd.append('files{}', file.file)
      // this.loading = true
      const post = 'https://f.zhulong.com/uploadZhulongImgForEdit.php?type=avatar'
      axios.post(post, fd).then(res => {
        // this.loading = false
        if (res.status && res.data) {
          const result = res.data
          const url = result.url
          // 修改信息的过来的
          if (this.fromChange) {
            this.avatarUrl = url
            this.canNext = true
          } else {
            this.uploadAvatarImg(url)
          }
        } else {
          const msg = res.statusText ? res.statusText : '上传失败'
          this.$toast.fail(msg)
        }
        if (!this.fromChange) {
          // 检测是否可下一步
          this.checkStep1CanClick()
        }
      })
    },
    // 上传头像
    uploadAvatarImg(url) {
      const p = {
        filename: url
      }
      updateAvatar(p).then(res => {
        const errNo = res.errNo
        const result = res.result
        if (errNo === 0 && result) {
          const url = result.result
          this.avatarUrl = url
          if (this.fromChange) {
            this.goBack()
          }
        } else {
          this.$toast.fail('图片上传失败')
        }
        // 检测是否可下一步
        this.checkStep1CanClick()
      })
    },
    // 确定传头像
    saveInfos() {
      if (this.setpNumber === 1) {
        const url = this.avatarUrl
        this.uploadAvatarImg(url)
      } else {
        this.saveStep2Info()
      }
    },
    // 检测是否可下一步
    checkStep1CanClick() {
      if (this.avatarUrl && this.gender >= 0) {
        this.canNext = true
      } else {
        this.canNext = false
      }
    },
    // 选城市
    clickForAddress(item) {
      this.handleItem = item
      this.showAddressPicker = true
      if (!this.isSelectedAddress) {
        setTimeout(() => {
          this.$refs.addRef.setIndexes([this.normalProvinceIndex, this.normalCityIndex, this.normalCountyIndex])
        }, 100)
      }
    },
    // 选择城市
    onConfirmAddress(values, indexs) {
      this.isSelectedAddress = true
      const provinceName = values[0]
      const cityName = values[1]
      const countyName = values[2]
      const provinceIdx = indexs[0]
      const cityIdx = indexs[1]
      const countyIdx = indexs[2]
      const province = this.addressList[provinceIdx]
      console.log(this.handleItem)
      this.handleItem.value1.province_id = province.id
      this.handleItem.value1.city_id = province.children[cityIdx].id
      this.handleItem.value1.county_id = province.children[cityIdx].children[countyIdx].id
      this.provinceAndCityName = provinceName + ' ' + cityName + ' ' + countyName
      this.showAddressPicker = false
      this.checkStep2CanClick()
    },
    // 选择专业
    onConfirmMajor(val, index, item) {
      item.value1.specialty = val.category_name
      item.value1.specialty_id = val.id
      console.log(val, '----name')
      console.log(item, '----name')
      this.showMajorPicker = false
      this.checkStep2CanClick()
    },
    // 选择学历
    onConfirmEdu(val, index, item) {
      item.value = val.value
      this.eduName = val.label
      this.showEduPicker = false
      this.checkStep2CanClick()
    },
    // 选择学历
    onConfirmWork(val, index, item) {
      item.value = val.value
      this.workYearNum = val.label
      this.showWorkPicker = false
    },
    // 选择性别
    onConfirmSex(val, index, item) {
      item.value = val.value
      this.showSexPicker = false
      this.canNext = false
    },
    // 选择性别
    onConfirmBirthday(date, item) {
      console.log()
      this.show = false
      const year = date.getFullYear()
      const month = date.getMonth() < 9 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1
      const day = date.getDay() < 9 ? '0' + (date.getDay() + 1) : date.getDay() + 1
      item.value = year + '-' + month + '-' + day
      this.showBirthdayCalendar = false
      this.canNext = false
    },
    // 时间格式
    formatter(type, val) {
      if (type === 'year') {
        return val + '年'
      }
      if (type === 'month') {
        return val + '月'
      }
      if (type === 'day') {
        return val + '日'
      }
      return val
    },
    // 选择了时间
    onConfirmDate(time) {
      this.handleItem.value = time
      this.showDatePicker = false
      this.checkStep2CanClick()
    },
    // 选择了证书
    onConfirmCertify(val) {
      console.log(val)
      this.showCertifyPicker = false
    },
    // 输入
    inputValue(val) {
      if (this.setpNumber === 2) {
        this.checkStep2CanClick()
      }
    },
    // 检测是否可下一步
    checkStep2CanClick(canToast) {
      console.log(this.pageListData)
      let disable = false
      for (let i = 0; i < this.pageListData.length; i++) {
        const item = this.pageListData[i]
        if (item.fields_remark != '头像' && item.type !== 8 && item.type !== 7) {
          if (item.required === 1) {
            if ((item.type == 10 || item.type == 6)) {
              if (!item.value1 || !item.value1.specialty_id) {
                disable = true
                if (canToast) {
                  this.$toast(item.fields_remark + '不能为空')
                }
                return
              }
            } else if (!item.value && item.value !== 0) {
              if (canToast) {
                this.$toast(item.fields_remark + '不能为空')
              }
              disable = true
              return
            }
          }
        }
      }
      if (disable) {
        this.canNext = false
      } else {
        this.canNext = true
      }
    },
    // 第2步保存信息
    onSubmitDatas() {
    },
    // 保存第一步信息
    saveStep1Info() {
      const post = {
        gender: this.gender
      }
      updateRegistInfo(post).then(res => {
        console.log(res)
      })
    },
    // 保存第2步信息
    saveStep2Info() {
      const paramObj = {}
      for (let i = 0; i < this.pageListData.length; i++) {
        const item = this.pageListData[i]
        if (item.fields_remark != '头像' && item.type !== 8 && item.type !== 7) {
          if (item.required === 1) {
            if ((item.type == 10 || item.type == 6)) {
              if (!item.value1 || !item.value1.specialty_id) {
                this.$toast(item.fields_remark + '不能为空')
                return
              }
            } else if (!item.value && item.value !== 0) {
              this.$toast(item.fields_remark + '不能为空')
              return
            }
          }
          if (item.type == 1 && (item.fields_remark.includes('标签') || item.fields_remark.includes('资格证书'))) {
            // 标签/证书
            if (item.fields_remark.includes('标签')) {
              paramObj[item.fields_name] = this.tagValue
            } else if (item.fields_remark.includes('资格证书')) {
              paramObj[item.fields_name] = this.certValue
            }
          } else if (item.type == 6) {
            // 地区
            paramObj[item.fields_name] = JSON.stringify(item.value1)
          } else if (item.type == 10) {
            // 专业/兴趣点
            paramObj[item.fields_name] = item.value1[item.fields_name]
          } else {
            paramObj[item.fields_name] = item.value
          }
        }
      }
      console.log(paramObj)
      // 小程序里 检测文本内容
      const wxopenid = Cookie.get('wxopenid')
      if (WechatState.isWechatMiniprogram && wxopenid) {
        // 小程序
        const check = {
          content: paramObj.username,
          openid: wxopenid,
          scene: '4' // 用户名传4
        }
        wxchectMessage(check).then(res => {
          if (res.errNo == 0) {
            // 成功
            this.saveStep2InfoRequest(paramObj)
          } else {
            const msg = res.msg || '审核不通过'
            this.$toast('用户名 ' + msg)
          }
        })
      } else {
        // 其他
        this.saveStep2InfoRequest(paramObj)
      }
    },
    // 保存第二步 信息接口
    saveStep2InfoRequest(paramObj) {
      newRegisInfo(paramObj).then((res) => {
        const errNo = res.errNo
        if (errNo === 0) {
          if (this.fromChange || this.fromSet) {
            if (paramObj.username != this.old_username) {
              this.$toast('用户名已修改，后续登录请用新用户名登录')
              setTimeout(() => {
                this.goBack()
              }, 1000)
            } else {
              this.goBack()
            }
          } else {
            this.setpNumber = 3
            this.canNext = false
          }
        } else {
          const msg = res.msg
          this.$toast.fail(msg)
        }
      })
    },
    // 跳过
    skip() {
      if (this.setpNumber === 1) {
        this.setpNumber = 2
      } else if (this.setpNumber === 2) {
        this.setpNumber = 3
      } else if (this.setpNumber === 3) {
        // 跳转
        window.location.href = this.redirect
      }
    },
    // 下一步
    clickNextStep() {
      if (this.setpNumber === 1) {
        // 第一步完成
        this.setpNumber = 2
        this.canNext = false
        // this.saveStep1Info()
      } else if (this.setpNumber === 2) {
        // 第二步完成
        this.saveStep2Info()
      }
    },
    // 添加关注
    addGuanzhu(val) {
      console.log(val.uid)
      const p = {
        uid: val.uid
      }
      addFollow(p).then(res => {
        const errNo = res.errNo
        if (errNo === 0) {
          val.is_follow = 1
          this.selCount++
          const msg = res.result
          this.$toast.success(msg)
          if (this.selCount === this.needCount) {
            // 关注完成
          }
        } else {
          const msg = res.result
          this.$toast.fail(msg)
        }
      })
    },
    // 第三步 完成
    doneSelectFans() {
      followGetScore().then(res => {
        console.log(res)
        const errNo = res.errNo
        if (errNo === 0) {
          const msg = '恭喜您获得' + this.attentionGet + this.scoreName
          this.$toast(msg)
          this.getAttentionScore()
        } else {
          const msg = res.result
          this.$toast(msg)
        }
      })
    },
    // 领取完奖品
    getAttentionScore() {
      if (this.fromChange) {
        this.goBack()
      } else {
        // 其实此处是完成，调回redirect链接
        this.skip()
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.cnt {
  width: 100%;
  min-height: 100%;
  text-align: center;
  background-color: #f2f2f2;
}
.header {
  height: 84px;
  width: 100%;
  position: relative;
  display: inline-block;
  .skip {
    margin-top: 20px;
    margin-right: 26px;
    float: right;
    width: 80px;
    height: 44px;
    line-height: 44px;
    font-family: PingFangSC-Regular;
    font-size: 32px;
    color: #999999;
    letter-spacing: 0;
    text-align: center;
  }
}
.step1 {
  .welcome {
    font-family: PingFangSC-Medium;
    font-size: 48px;
    color: #333333;
    letter-spacing: 0;
    text-align: center;
  }
  .avatarbg {
    margin-top: 40px;
    width: 220px;
    height: 220px;
    border-radius: 110px;
    overflow: hidden;
    position: relative;
  }
  .avatar {
    width: 100%;
    height: 100%;
  }
  .edit {
    position: absolute;
    display: inline-block;
    left: 0;
    bottom: 0;
    width: 220px;
    height: 72px;
    line-height: 72px;
    opacity: 0.75;
    font-family: PingFangSC-Regular;
    font-size: 32px;
    color: #ffffff;
    text-align: center;
    background-color: #000000;
  }
  .info {
    margin-top: 40px;
    font-family: PingFangSC-Medium;
    font-size: 30px;
    color: #2a2a2a;
    text-align: center;
  }
  .info1 {
    margin-top: 10px;
  }
  .sex {
    margin-top: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    .btn1 {
      margin: 0 20px;
      background-color: #fff;
      width: 256px;
      height: 90px;
      border-radius: 45px;
      display: flex;
      justify-content: center;
      align-items: center;
      span {
        margin-left: 10px;
        font-family: PingFangSC-Medium;
        font-size: 30px;
        color: #cbcbcb;
      }
      img {
        width: 48px;
        display: block;
      }
    }
    .btn2 {
      margin: 0 20px;
      background-color: #ee2e2e;
      width: 256px;
      height: 90px;
      border-radius: 45px;
      display: flex;
      justify-content: center;
      align-items: center;
      span {
        margin-left: 10px;
        font-family: PingFangSC-Medium;
        font-size: 30px;
        color: #fff;
      }
      img {
        width: 48px;
        display: block;
      }
    }
  }
}
.step2 {
  width: 100%;
  .topInfoTop {
    margin-top: 40px;
  }
  .topInfo {
    padding: 0 50px;
    text-align: center;
    .info1 {
      font-family: PingFangSC-Medium;
      font-size: 48px;
      color: #333333;
    }
    .info2 {
      margin-top: 26px;
      font-family: PingFangSC-Regular;
      font-size: 26px;
      color: #999999;
    }
    .info3 {
      margin-top: 40px;
      font-family: PingFangSC-Medium;
      font-size: 30px;
      color: #666666;
    }
  }
  .table {
    margin-left: 30px;
    margin-top: 30px;
    width: calc(100% - 60px);
    /deep/.van-cell::after {
      border-bottom: 1px solid #ddd;
    }
    /deep/.van-field__label {
      color: #333333;
      font-family: PingFangSC-Medium;
      font-size: 30px;
    }
    /deep/.van-field__control {
      font-family: PingFangSC-Regular;
      font-size: 30px;
      color: #333;
    }
    /deep/.van-field__label {
      text-align: justify;
      text-align-last: justify;
    }
    .inputAndIcon {
      position: relative;
      .rightArrow {
        width: 16px;
        position: absolute;
        right: 40px;
        top: 30px;
        z-index: 100;
      }
    }
  }
}
.step3 {
  width: 100%;
  padding-bottom: 160px;
  .topInfoTop {
    margin-top: 40px;
  }
  .topInfo {
    padding: 0 50px;
    text-align: center;
    .info1 {
      font-family: PingFangSC-Medium;
      font-size: 48px;
      color: #333333;
      text-align: center;
    }
    .info2 {
      margin-top: 20px;
      font-family: PingFangSC-Medium;
      font-size: 26px;
      color: #666666;
      text-align: center;
    }
  }
  .listBox {
    width: 100%;
    margin-top: 40px;
    background-color: #f2f2f2;
    .list {
      margin-left: 30px;
      padding: 20px 0;
      width: calc(100% - 60px);
      display: flex;
      flex-direction: row;
      align-items: center;
      .left {
        width: 100px;
        height: 100px;
        border-radius: 50px;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .midInfo {
        margin-left: 30px;
        margin-right: 10px;
        width: calc(100% - 285px);
        text-align: left;
        .uname {
          opacity: 0.99;
          font-family: PingFangSC-Medium;
          font-size: 32px;
          color: #333333;
          word-break: break-all;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
        }
        .infos {
          margin-top: 6px;
          font-family: PingFangSC-Regular;
          font-size: 24px;
          color: #999999;
          .from {
            margin-left: 30px;
          }
        }
      }
      .right {
        width: 145px;
        .guanzhu {
          font-family: PingFangSC-Regular;
          font-size: 24px;
          color: #ffffff;
          display: inline-block;
          width: 100%;
          height: 56px;
          line-height: 56px;
          border-radius: 28px;
          background-color: #ee2e2e;
        }
        .isguanzhu {
          font-family: PingFangSC-Regular;
          font-size: 24px;
          color: #999999;
          letter-spacing: 0;
          display: inline-block;
          width: 100%;
          height: 56px;
          line-height: 56px;
          border-radius: 28px;
          background-color: #e6e6e6;
        }
      }
    }
  }
}

.bot {
  margin-top: 40px;
  padding-bottom: 40px;
  .nextBtn {
    display: inline-block;
    width: calc(100% - 60px);
    height: 88px;
    line-height: 88px;
    border-radius: 10px;
    font-family: PingFangSC-Medium;
    font-size: 36px;
    color: #ffffff;
    text-align: center;
  }
  .bg {
    background-color: #f19191;
  }
  .bg1 {
    background-color: #ee2e2e;
  }
}
.botDone {
  width: 100%;
  height: 140px;
  background-color: #fff;
  position: fixed;
  bottom: 0;
  .doneBtn {
    display: inline-block;
    margin: 26px 30px;
    width: calc(100% - 60px);
    height: 88px;
    line-height: 88px;
    border-radius: 10px;
    font-family: PingFangSC-Medium;
    font-size: 30px;
    color: #ffffff;
    text-align: center;
  }
  .bg {
    background-color: #f19191;
  }
  .bg1 {
    background-color: #ee2e2e;
  }
}
.prop_box {
  width: 100%;
  height: 100vh;
  max-height: 100vh;
  overflow-y: scroll;
  background: #f2f2f2;
  text-align: left;
  .prop_box_top {
    width: 100%;
    height: 76px;
    background: white;
    h1 {
      font-size: 0.4rem;
      text-align: center;
      line-height: 76px;
    }
  }
  .prop_box_bottom {
    margin-top: 3%;
    margin-left: 5%;
    h2 {
      font-size: 0.36rem;
      margin-bottom: 25px;
      color: #454545;
    }
    .itemProfress {
      display: inline-block;
      padding: 22px 20px;
      font-size: 27px;
      border: 2px solid #e5e5e5;
      border-radius: 60px;
      min-width: 150px;
      text-align: center;
      background-color: #fff;
      color: #666;
      margin: 0 10px 10px 0;
      &:hover {
        background: #1f1f22;
        color: #fff;
      }
    }
  }
}
</style>
